import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import OurWorkslef from "../components/hire/OurWorkself";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `eCommerce Design Agency`,
    text: "Ecommerce Web Design Solutions",
    content: "Our eCommerce design agency offers comprehensive web design services to maximize the online presence of businesses. We blend creativity and functionality to craft captivating digital experiences and foster customer loyalty in the competitive digital space.",
    list: [
      'Better Digital Experiences',
      'Increased Customer Retention',
      'Mobile First Approach'
    ]
  };
  const ourData = {
    title: "Client’s Take On eCommerce  Web Design Company",
    list1: [
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'Octet has been very supportive to our design and technical requirements. Their designers and developers have always helped us to deliver creative and on-time solutions to our marketing needs.',
        name: 'Mariam Khan',
        des: 'Head Of Digital Marketing'
      },
      {
        logo: ['adani.webp'],
        stars: 5,
        text: "Octet Designers were instrumental in transforming our internal products and processes.",
        name: 'Naveen Kumar',
        des: 'QA Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: "The team's flexibility and understanding of our business needs impressed us with their fast turnaround.",
        name: 'Ishan',
        des: 'Group Product Manager'
      }
    ],
    list2: [
      {
        logo: ['visa.webp'],
        stars: 5,
        text: 'With so many things we could be doing in our product, it was hard to streamline and decide where to invest our time and effort.',
        name: 'Vipin D',
        des: 'Customer Success Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Using Design first approach with Octet’s designers gave us more clarity and streamlined our solution and delivery.',
        name: 'Todd Baughman',
        des: 'SVP Global Operations'
      },
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'They have delivered more than what they have committed. They made our sophisticated platform super simple and classic, our customers love using our app than any competitors.',
        name: 'Parag Chivate',
        des: 'Founder and CEO'
      },
    ]
  };
  const commonContent = {
    title: "Our Expertise in eCommerce Web Design Services",
    content: [
      {
        title: "100+ Projects Completed",
        text: "With over 100+ completed e-commerce projects, we are experts in designing high-quality, custom e-commerce solutions that drive online success for diverse businesses.",
      },
      {
        title: "80% Increase in Average Sales",
        text: "Our e-commerce design services have increased the average sales by up to 80% for our clients, highlighting our effectiveness in creating functional online stores.",
      },
      {
        title: "70% Boost in Customer Retention",
        text: "Our ecommerce web design services have led to a 70% boost in customer retention, ensuring that users return and remain loyal to our client’s online stores.",
      },
      {
        title: "300+ Custom Features Integrated",
        text: "We have integrated over 300 custom features across various ecommerce sites, enhancing functionality and providing unique user experiences catering to specific business needs.",
      },
    ],
  };
  const commonContent1 = {
    title: "Maximize Potential With Ecommerce Design Agency",
    content: [
      {
        title: "Expert Ecommerce Designers",
        text: "Our team of expert ecommerce designers brings extensive experience and creativity, ensuring your online store is visually appealing and highly functional.",
      },
      {
        title: "Platform Knowledge",
        text: "With in-depth knowledge of major ecommerce platforms like Shopify, WooCommerce, and Magento, we deliver solutions that perfectly fit your business needs.",
      },
      {
          title: "Cross-Browser Compatibility",
          text: "Our ecommerce web design solutions are tested for compatibility across all major browsers, ensuring a consistent and flawless experience for your users.",
        },
        {
            title: "Responsive Design",
            text: "We design mobile-first and fully responsive websites, guaranteeing a seamless shopping experience across all devices, which is crucial for reaching a wider audience.",
        },
        {
          title: "Personalized User Journey",
          text: "Our custom ecommerce web design services focus on creating personalized customer journeys, enhancing engagement, and increasing the chances of repeat purchases.",
        },
      {
        title: "Faster Load Times",
        text: "With ecommerce design services, we optimize website performance, ensure faster load times, and reduce bounce rates, increasing user retention and positively impacting sales.",
      },
    ],
  };
  const Questions = [
    "What are the e-commerce web design services do you offer?",
    "Why should I choose Octet for ecommerce web design and development?",
    "How can you add value to our e-commerce platform?",
    "How much time does it take to design an e-commerce platform?",
    'Do you offer post-launch support to our e-commerce website?'
  ];
  const faqData = [
    {
        para: [
            `The ecommerce design services that we provide to businesses are:`,
        ],
        list: [
            `<span><h4 class="inline">E-commerce Website Design:</h4></span> We create visually appealing and functional websites tailored specifically for your e-commerce business, ensuring an intuitive and seamless experience for your customers.`,
            `<span><h4 class="inline">E-commerce UI Design:</h4></span> Our ecommerce web design company enhances the visual appeal and usability of your website, optimizing interactions between users and the site's interface elements.`,
            `<span><h4 class="inline">E-commerce UX Design:</h4></span> We create intuitive and user-friendly experiences for your e-commerce platforms, prioritizing ease of navigation, efficient task completion, and overall customer satisfaction.`,
            `<span><h4 class="inline">E-commerce App Design:</h4></span> We design mobile apps specifically tailored for e-commerce businesses, providing users with a seamless shopping experience on smartphones and tablets.`,
            `<span><h4 class="inline">E-commerce Design Audit:</h4></span> Our ecommerce design agency conducts comprehensive assessments of existing e-commerce designs to identify strengths, weaknesses, and areas for improvement, ensuring optimal performance and user experience.`,
            `<span><h4 class="inline">E-commerce Dashboard Design</h4></span> With our ecommerce web design solution, we design intuitive dashboards that provide valuable insights and analytics to help monitor and optimize sales, inventory, and other key metrics.`,
            `<span><h4 class="inline">E-commerce Design System</h4></span> With our custom ecommerce web design services, we develop a cohesive and scalable design system, ensuring consistency and efficiency across all aspects of the user interface and experience..`
        ]
    },
    {
      para: [
        `We stand out for expertise, creativity, and dedication to the client’s success. With years of experience in ecommerce web design and development, we have a proven track record of delivering high-quality, customized solutions that drive results`,
        `Our team of skilled professionals combines technical expertise with creativity to create visually stunning and highly functional e-commerce platforms that effectively showcase your products and drive conversions.`
      ],
    },
    {
        para: [
            `Being an ecommerce web design company, we prioritize user experience, ensuring your website is intuitive, easy to navigate, and optimized for conversions. `,
            `Additionally, we also integrate advanced features and functionalities to enhance the overall shopping experience and increase customer satisfaction. `,
            `Furthermore, we provide ongoing support and maintenance to ensure that your e-commerce platform remains up-to-date, secure, and performing at its best.`
        ]
    },
    {
      para: [
        `The time required to design an e-commerce platform can vary depending on factors such as the complexity of the project, the number of features and functionalities required, and the level of customization needed. `,
        `We work closely with our clients to establish clear timelines and milestones to ensure timely delivery of the project. We request you <a href="/contact-us/" title="contact us">contact us</a> to discuss the timeline in detail.`
      ],
    },
    {
        para: [
            `Yes, we do offer post-launch support to ensure that your e-commerce website continues to perform optimally after its launch.`
        ]
    }
  ];
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
         {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "What are the e-commerce web design services do you offer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The ecommerce design services that we provide to businesses are:
          E-commerce Website Design
          We create visually appealing and functional websites tailored specifically for your e-commerce business, ensuring an intuitive and seamless experience for your customers.
          E-commerce UI Design
          Our ecommerce web design company enhances the visual appeal and usability of your website, optimizing interactions between users and the site's interface elements.
          E-commerce UX Design
          We create intuitive and user-friendly experiences for your e-commerce platforms, prioritizing ease of navigation, efficient task completion, and overall customer satisfaction.
          E-commerce App Design
          We design mobile apps specifically tailored for e-commerce businesses, providing users with a seamless shopping experience on smartphones and tablets.
          E-commerce Design Audit
          Our ecommerce design agency conducts comprehensive assessments of existing e-commerce designs to identify strengths, weaknesses, and areas for improvement, ensuring optimal performance and user experience.
          E-commerce Dashboard Design
          With our ecommerce web design solution, we design intuitive dashboards that provide valuable insights and analytics to help monitor and optimize sales, inventory, and other key metrics.
          E-commerce Design System
          With our custom ecommerce web design services, we develop a cohesive and scalable design system, ensuring consistency and efficiency across all aspects of the user interface and experience."
              }
            },{
              "@type": "Question",
              "name": "Why should I choose Octet for e-commerce web design and development?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We stand out for expertise, creativity, and dedication to the client’s success. With years of experience in e-commerce web design and development, we have a proven track record of delivering high-quality, customized solutions that drive results. 
          
          Our team of skilled professionals combines technical expertise with creativity to create visually stunning and highly functional e-commerce platforms that effectively showcase your products and drive conversions."
              }
            },{
              "@type": "Question",
              "name": "How can you add value to our e-commerce platform?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Being an ecommerce web design company, we prioritize user experience, ensuring your website is intuitive, easy to navigate, and optimized for conversions. 
          
          Additionally, we also integrate advanced features and functionalities to enhance the overall shopping experience and increase customer satisfaction. 
          
          Furthermore, we provide ongoing support and maintenance to ensure that your e-commerce platform remains up-to-date, secure, and performing at its best."
              }
            },{
              "@type": "Question",
              "name": "How much time does it take to design an e-commerce platform?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The time required to design an e-commerce platform can vary depending on factors such as the complexity of the project, the number of features and functionalities required, and the level of customization needed. 
          
          We work closely with our clients to establish clear timelines and milestones to ensure timely delivery of the project. We request you contact us to discuss the timeline in detail."
              }
            },{
              "@type": "Question",
              "name": "Do you offer post-launch support to our e-commerce website?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, we do offer post-launch support to ensure that your e-commerce website continues to perform optimally after its launch."
              }
            }]
          }                                                       
        `}
      </script>
    </Helmet>
    <Layout hire="ecommerce design agency" hotjarHire={true} footerText="Maximize your conversions with Us">
      <div>
        <HireBanner data={banner} hire pageName="ecommerce design agency" />
        <section className="py-[40px]">
          <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
            <div className="lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <OurWorkslef data={ourData} hire />
        <CommonServiceComponent data={commonContent} mode={"dark"} hire />
        <CommonServiceComponent data={commonContent1} mode={"light"} hire />
        <Faq title="FAQ About eCommerce <span class='h1-span'>Design Services</span>" grey section Questions={Questions} faqData={faqData} />
      </div>
    </Layout>
    </>
  );
};

export default HirePage;

export const Head = () => (
  <Seo
    title="Ecommerce Design Agency | Ecommerce Web Design Solutions"
    description="Transform your online presence with our ecommerce design agency. We tailor solutions to elevate your brand and boost sales. Contact us today!"
  />
);
